import * as React from "react";
import { motion } from "framer-motion";
import tw, { styled } from "twin.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

const Container = styled(motion.div)`
  ${tw`w-2/5`}
  padding: 0;
  width: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background-color: #57606f;
  position: fixed;
  z-index: 5;
`;

const item = {
  hide: { width: "0px" },
  show: { width: "200px" },
};

const Sidebar = (props) => {
  const { status, onDismissClick } = props;

  return (
    <Container variants={item} animate={status}>
      <div className="text-right mr-4" onClick={onDismissClick}>
        <FontAwesomeIcon icon={faTimes} size="3x" />
      </div>

      <ul className="space-y-5">
        <li className="mx-3 text-white font-bold font" onClick={onDismissClick}>
          <a href="#about">About</a>
        </li>
        <li className="mx-3 text-white font-bold" onClick={onDismissClick}>
          <a href="#experience">Experience</a>
        </li>
        <li className=" mx-3 text-white font-bold" onClick={onDismissClick}>
          <a href="#projects">Projects</a>
        </li>
      </ul>
    </Container>
  );
};

export default Sidebar;
