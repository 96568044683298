import React, { useState } from "react";
import { Link } from "gatsby";
import Sidebar from "./sidebar";
import SidebarButton from "./sidebarButton";

const headingAccentStyles = {
  color: "#663399",
};

const Heading = ({ title, subtitle }) => {
  const [show, setShow] = useState("hide");

  const disableScroll = () => (document.body.style.overflow = "hidden");

  const enableScroll = () => (document.body.style.overflow = "scroll");

  const onShowSidebar = () => {
    disableScroll();
    setShow("show");
  };

  const onHideSidebar = () => {
    enableScroll();
    setShow("hide");
  };

  return (
    <div className="md:w-3/4 mx-auto">
      <div className="flex justify-between mx-auto px-4 py-4 max-w-screen-lg">
        <Link to="/">
          <h1 className="text-4xl text-white font-bold">
            {title}
            <br />
            {subtitle && <span style={headingAccentStyles}>— {subtitle} </span>}
          </h1>
        </Link>
        <Sidebar status={show} onDismissClick={onHideSidebar} />

        <SidebarButton onButtonClick={onShowSidebar} />
        <div className="hidden sm:block self-center">
          <ul>
            <li className="inline mx-3 text-white font-bold">
              <a href="#about">About</a>
            </li>
            <li className="inline mx-3 text-white font-bold">
              <a href="#experience">Experience</a>
            </li>
            <li className="inline mx-3 text-white font-bold">
              <a href="#projects">Projects</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Heading;
