import * as React from "react";
import { Helmet } from "react-helmet";
import { motion } from "framer-motion";
import Heading from "../Heading";

const pageStyles = {
  color: "#232129",
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
  backgroundColor: "#2f3542",
  minHeight: "100vh",
  width: "100%",
};

const Page = ({ location, children }) => {
  return (
    <main style={pageStyles}>
      <Helmet
        defaultTitle="Passawis"
        title={
          location.pathname === "/" ? "Passawis | About" : "Passawis | Projects"
        }
      />
      <Heading title="Passawis" />
      <motion.div
        className="pb-12"
        // initial={{ opacity: 0, x: -200 }}
        // animate={{ opacity: 1, x: 0 }}
        // exit={{ opacity: 0, x: 200 }}
        // transition={{
        //   type: "spring",
        //   mass: 0.25,
        //   stiffness: 100,
        //   duration: 0,
        // }}
      >
        {children}
      </motion.div>
    </main>
  );
};

export default Page;
